(function ($) {

    // $.proofguide('/wp-content/themes/pp-v1.2-children/allison-winkler/resources/guide.jpg');

    $(window).load(function () {
        $('.testimonial-flexslider-2').slick({
            slidesToShow: 3,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 6000,
            centerMode: true,
            variableWidth: true,
            adaptiveHeight: true,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 1,
                    }
                }
            ]
        });
    });

})(jQuery);

